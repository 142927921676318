import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { SiteType } from '../datas/site';

export type SeoData = {
  description?: string;
  meta?: JSX.IntrinsicElements['meta'][];
  title: string;
};

const Seo = ({ description, meta = [], title }: SeoData): JSX.Element => {
  const { site } = useStaticQuery<SiteType>(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);
  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'ja',
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        ...meta,
      ]}
    />
  );
};
export default Seo;
