import { graphql, Link, StaticQuery } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import React from 'react';

export type AsideData = {
  taskEggImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  googlePlayBadgeImage: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
};

const AsideWithoutData = ({ data }: { data: AsideData }): JSX.Element => (
  <div>
    <div className="box">
      <h3 className="subtitle">Menu</h3>
      <ul>
        <li><p className="is-size-7"><Link to="/">トップページ</Link></p></li>
        <li><p className="is-size-7"><Link to="/about-blog">本ブログについて</Link></p></li>
        <li><p className="is-size-7"><Link to="/tags/">タグ一覧</Link></p></li>
        <li><p className="is-size-7"><a href="/rss.xml">RSS</a></p></li>
      </ul>
    </div>

    <div className="box">
      <h3 className="subtitle">Product</h3>
      <a href="https://www.re-arc-lab.jp/product/task-egg">
        <Img
          className="mt-5"
          fluid={data.taskEggImage.childImageSharp.fluid}
          alt="task egg"
        />
      </a>
      <p className="is-size-7">「いつやるかは決めてないけれど、必ずやらなければいけないタスク」を忘れないためのアプリ</p>
      <a href="https://play.google.com/store/apps/details?id=jp.re_arc_lab.task_egg">
        <Img
          className="mt-5"
          fluid={data.googlePlayBadgeImage.childImageSharp.fluid}
          alt="Google Play で手に入れよう"
        />
      </a>
    </div>

    <div className="box">
      <h3 className="subtitle">Link</h3>
      <ul>
        <li><p className="is-size-7"><a href="https://www.re-arc-lab.jp/">リアキテク・ラボ</a></p></li>
        <li><p className="is-size-7"><a href="https://www.re-arc-lab.jp/privacy-policy">プライバシーポリシー</a></p></li>
        <li><p className="is-size-7"><a href="https://www.re-arc-lab.jp/contact">Contact</a></p></li>
      </ul>
    </div>
  </div>
);

export const Aside = (): JSX.Element => (
  <StaticQuery
    query={graphql`
        query AsideQuery {
          taskEggImage: file(relativePath: { eq: "task-egg.png" }) {
            childImageSharp {
              fluid(maxWidth: 128) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          googlePlayBadgeImage: file(relativePath: { eq: "google-play-badge.png" }) {
            childImageSharp {
              fluid(maxWidth: 128) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
    render={(data) => (
      <AsideWithoutData
        data={data}
      />
    )}
  />
);

export default Aside;
