import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Seo, { SeoData } from './seo';
import { SiteType } from '../datas/site';
import { Aside } from './aside';

const Layout = ({ seo, hero, children }: {
  seo: SeoData;
  hero?: boolean;
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const { site } = useStaticQuery<SiteType>(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <div>
      <Seo
        description={seo.description}
        meta={seo.meta}
        title={seo.title}
      />
      <header>
        {hero ? (
          <section className="hero is-dark">
            <div className="hero-body">
              <div className="container">
                <h1 className="title is-size-4 has-text-weight-light">{site.siteMetadata.title}</h1>
              </div>
            </div>
          </section>
        ) : (
          <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
              <Link className="navbar-item" to="/">
                {site.siteMetadata.title}
              </Link>
            </div>
          </nav>
        )}
      </header>
      <main>
        <section className="section">
          <div className="columns">
            <div className="column is-four-fifths">
              {children}
            </div>
            <div className="column"><Aside /></div>
          </div>
        </section>
      </main>
    </div>
  );
};
Layout.defaultProps = {
  hero: false,
};

export default Layout;
